export function useYandexPay() {
  const siteStore = useSiteStore();
  const cartStore = useCartStore();
  const checkoutStore = useCheckoutStore();

  watch(
    () => cartStore.cart.calculate.total,
    () => refreshYandexPaySession(),
  );

  watch(
    () => checkoutStore.deliveryCost,
    () => refreshYandexPaySession(),
  );

  async function refreshYandexPaySession() {
    if (siteStore.activeYandexPaySession) {
      siteStore.activeYandexPaySession.destroy();
      siteStore.activeYandexPaySession = null;
    }

    siteStore.activeYandexPaySession = await createYandexPaySession();
  }

  const { yandexPayMerchantId } = useRuntimeConfig().public;

  async function createYandexPaySession(amount?: number) {
    const yaPay = window?.YaPay;

    if (!yaPay) return;

    const paymentData = {
      availablePaymentMethods: ['SPLIT'],
      currencyCode: yaPay.CurrencyCode.Rub,
      totalAmount: amount ?? cartStore.cart.calculate.total + checkoutStore.deliveryCost,
      version: 4,
      merchantId: yandexPayMerchantId,
    };

    const onPayButtonClick = () => {};

    try {
      const paymentSession = await yaPay.createSession(paymentData, { onPayButtonClick });

      return paymentSession ?? null;
    } catch (e) {
      console.error(e);
    }
  }

  return { createYandexPaySession, refreshYandexPaySession };
}
